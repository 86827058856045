import { useCallback } from 'react';
import compact from 'lodash/compact';
import type { PageVariableState } from '@unifyapps/defs/types/pageVariable';
import { useGetInterfaceStoreState, useInterfaceStore } from '../../stores/InterfaceStore';
import { useBlockRuntimeData } from '../../components/BlockRuntimeStateProvider';
import { useGetGlobalStateStoreState, useGlobalStateStore } from '../../stores/GlobalStateStore';
import { formatDate } from '../../../utils/date';
import { formatTime } from '../../../utils/time';
import { formatNumber } from '../../../utils/number';
import { adaptSearchToFilter } from '../../../hooks/useAggregationQueryBuilder';
import { safeJsonParse, safeJsonStringify } from '../../../utils/json';
import { useInterfaceEntitiesMethods } from '../../components/InterfaceEntitiesMethodsProvider/InterfaceEntitiesMethodsProvider';
import { getCombinedBlockState } from './utils/getCombinedBlockState';
import getInterfaceRecordForState from './utils/getInterfaceRecordForState';
import { getCombinedDataSourceState } from './utils/getCombinedDataSourceState';
import { getCombinedPageVariables } from './utils/getCombinedPageVariables';

export const ComputeContextKeys = {
  PAGE_VARIABLES: '__PAGE_VARS__',
  PAGE_FUNCTIONS: '__PAGE_FUNCTIONS__',
  SESSION_STORAGE: '__SESSION_STORAGE__',
  LOCATION: 'location',
  UTILS: 'utils',
  PAGE_INPUTS: 'pageInputs',
  USER_CONTEXT: 'userContext',
  INTERFACE_RECORD: 'interfaceRecord',
};

const utils = {
  formatDate,
  formatTime,
  compact,
  adaptSearchToFilter,
  parseJson: safeJsonParse,
  stringifyJson: safeJsonStringify,
  formatNumber,
};

function adaptPageVariablesForContext(
  pageVariables: Record<string, PageVariableState | undefined>,
) {
  // Page variable is stored in store as:
  // {
  //   [variableName: string]: {
  //     initialValue: any;
  //     value: any;
  //     type: string;
  //   };
  // }
  // but we are showing it in the input data pill picker as
  // {
  //   [variableName: string]: value
  // }
  // so we are adapting the page variables to the format that is used in the input data pill picker
  return Object.entries(pageVariables).reduce<Record<string, unknown>>((acc, [key, value]) => {
    if (value) {
      acc[key] = value.value;
    }
    return acc;
  }, {});
}

export function useGetComputeContext() {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const blocksRuntimeState = useBlockRuntimeData() as Record<string, Record<string, unknown>>;
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const interfaceEntityMethods = useInterfaceEntitiesMethods();

  const getComputeContext = useCallback(() => {
    const blocksState = getGlobalStateStoreState().blocks;
    const dataSourcesState = getGlobalStateStoreState().dataSources;
    const dataSourcesMeta = getGlobalStateStoreState().dataSourcesMeta;
    const interfaceRecord = getInterfaceStoreState().interfaceRecord;
    const pageInputs = getGlobalStateStoreState().pageInputs;
    const location = getGlobalStateStoreState().location;
    const userContext = getGlobalStateStoreState().userContext;
    const interfaceSessionStorage = getGlobalStateStoreState().interfaceSessionStorage;
    const pageVariables = adaptPageVariablesForContext(getGlobalStateStoreState().pageVariables);
    const pageFunctions = getGlobalStateStoreState().pageFunctions;

    const combinedBlockState = getCombinedBlockState({
      blocksState,
      blocksRuntimeState,
      blockMethods: interfaceEntityMethods.blocks,
    });

    const combinedDataSourceState = getCombinedDataSourceState({
      dataSourcesState,
      dataSourceMethods: interfaceEntityMethods.dataSources,
    });

    const combinedPageVariables = getCombinedPageVariables({
      pageVariables,
      pageVariablesMethods: interfaceEntityMethods.pageVariables,
    });

    return {
      ...combinedDataSourceState,
      ...combinedBlockState,
      dataSourcesMeta,
      [ComputeContextKeys.PAGE_INPUTS]: pageInputs,
      [ComputeContextKeys.SESSION_STORAGE]: interfaceSessionStorage,
      [ComputeContextKeys.LOCATION]: location,
      [ComputeContextKeys.USER_CONTEXT]: userContext,
      [ComputeContextKeys.INTERFACE_RECORD]: getInterfaceRecordForState(interfaceRecord),
      [ComputeContextKeys.UTILS]: utils,

      /**
       * Page Variables and Functions Assignment
       *
       * @deprecated This implementation is slated for removal in a future update.
       *
       * Historical Context:
       * - Previously, page variables and functions were stored under objects
       *   with keys __PAGE_VARS__ and __PAGE_FUNCTIONS__ respectively.
       * - This was done to maintain backward compatibility with existing code.
       *
       * Current Implementation:
       * - Page variables and functions are now stored flat in the compute context.
       *
       *
       */
      ...combinedPageVariables,
      ...pageFunctions,
      [ComputeContextKeys.PAGE_VARIABLES]: pageVariables,
      [ComputeContextKeys.PAGE_FUNCTIONS]: pageFunctions,
    };
  }, [
    blocksRuntimeState,
    getGlobalStateStoreState,
    getInterfaceStoreState,
    interfaceEntityMethods.blocks,
    interfaceEntityMethods.dataSources,
    interfaceEntityMethods.pageVariables,
  ]);

  return {
    getComputeContext,
  };
}

export function useReactiveComputeContext() {
  const interfaceEntityMethods = useInterfaceEntitiesMethods();

  const blocksRuntimeState = useBlockRuntimeData() as Record<string, Record<string, unknown>>;
  const blocksState = useGlobalStateStore().use.blocks();
  const interfaceRecord = useInterfaceStore().use.record.details();
  const dataSourcesState = useGlobalStateStore().use.dataSources();
  const dataSourcesMeta = useGlobalStateStore().use.dataSourcesMeta();
  const pageInputs = useGlobalStateStore().use.pageInputs();
  const location = useGlobalStateStore().use.location();
  const userContext = useGlobalStateStore().use.userContext();
  const pageVariables = adaptPageVariablesForContext(useGlobalStateStore().use.pageVariables());
  const pageFunctions = useGlobalStateStore().use.pageFunctions();
  const interfaceSessionStorage = useGlobalStateStore().use.interfaceSessionStorage();

  const combinedBlockState = getCombinedBlockState({
    blocksState,
    blocksRuntimeState,
    blockMethods: interfaceEntityMethods.blocks,
  });

  const combinedDataSourceState = getCombinedDataSourceState({
    dataSourcesState,
    dataSourceMethods: interfaceEntityMethods.dataSources,
  });

  const combinedPageVariables = getCombinedPageVariables({
    pageVariables,
    pageVariablesMethods: interfaceEntityMethods.pageVariables,
  });

  const context = {
    ...combinedDataSourceState,
    ...combinedBlockState,

    dataSourcesMeta,
    [ComputeContextKeys.PAGE_INPUTS]: pageInputs,
    [ComputeContextKeys.SESSION_STORAGE]: interfaceSessionStorage,
    [ComputeContextKeys.LOCATION]: location,
    [ComputeContextKeys.USER_CONTEXT]: userContext,
    [ComputeContextKeys.INTERFACE_RECORD]: getInterfaceRecordForState(interfaceRecord),
    [ComputeContextKeys.UTILS]: utils,
    /**
     * Page Variables and Functions Assignment
     *
     * @deprecated This implementation is slated for removal in a future update.
     *
     * Historical Context:
     * - Previously, page variables and functions were stored under objects
     *   with keys __PAGE_VARS__ and __PAGE_FUNCTIONS__ respectively.
     * - This was done to maintain backward compatibility with existing code.
     *
     * Current Implementation:
     * - Page variables and functions are now stored flat in the compute context.
     *
     *
     */
    ...combinedPageVariables,
    ...pageFunctions,
    [ComputeContextKeys.PAGE_VARIABLES]: pageVariables,
    [ComputeContextKeys.PAGE_FUNCTIONS]: pageFunctions,
  };

  return { context };
}
