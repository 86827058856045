import type { ReactNode } from 'react';
import { clsx } from 'clsx';

export function PageWorkspace({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <main
      className={clsx(
        'ua-page-workspace bg-workspace flex min-h-0 flex-1 flex-col overflow-hidden',
        className,
      )}
    >
      {children}
    </main>
  );
}
