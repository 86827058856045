export const RUN_TIME_BLOCK_KEY = 'runTimeBlockKey';

export function getRunTimeBlockId({
  originalBlockId,
  runTimeBlockKey,
}: {
  originalBlockId: string;
  runTimeBlockKey: string;
}) {
  return `${RUN_TIME_BLOCK_KEY}_${originalBlockId}_${runTimeBlockKey}`;
}
